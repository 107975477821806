import React from "react";
import BackgroundChecks from "../../../components/Landers/BackgroundChecks";
import Banner from "../../../components/Landers/Banner";
import BrandsTemp from "../../../components/Landers/BrandsTemp";
import CustomizedSolutions from "../../../components/Landers/CustomizedSolutions";
import Footer from "../../../components/Landers/Footer";
import AllCheck from "../../../components/Landers/AllCheck";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import DataInfo from "../../../components/Data";

const solutions = [
  {
    h5: "Super- Fast and Accurate Screening",
    p:
      "Run a variety of checks on multiple candidates at once to kickstart your screening process. Instant hiring decision is often the most favored feature for any employer, and SpringVerify makes it easy with the best turnaround times in the industry.",
  },
  {
    h5: "Enhanced Workflow Automation",
    p:
      "SpringVerify’s easy-to-use platform increases productivity by automating all obsolete manual workloads such as Administrative management, accounting, and billing invoices for clients, which contributes to lower TAT in the industry. In addition, you can get transparent, real-time reports for the checks running on your candidates as they happen.",
  },
  {
    h5: "Built-in Candidate portal",
    p:
      "From filling up BGV forms to running real-time checks, everything is automated and made super simple for the best candidate experience. In addition, with our mobile-optimized built-in dashboard, candidates can now track the status of their background checks anywhere, anytime.",
  },
];

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={`${styles.staffing} ${styles.m0}`}>
    <SEO
      title="Background Check for Staffing Agencies | SpringVerify India"
      desc="Make reliable hiring decisions whether it's sourcing, recruiting, or staffing candidates with SpringVerify’s robust screening solutions designed for your Staffing agency."
      url="https://in.springverify.com/industry/staffing-agencies/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/solution/SV-IN+(Staffing).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1="Background Screening Solutions for Staffing Agencies"
        p="Make reliable hiring decisions at scale with SpringVerify’s robust screening solutions built for your Staffing agency."
        img="https://assets-sv-in.gumlet.io/LP/Staffing/banner.png"
      />
    </div>
    <BrandsTemp data={DataInfo.HRBrandsTemp} />
    <section>
      <SectionTemplate
        img="Staffing/need.svg"
        h2="Need for Background Verifications in Staffing Agencies"
        p={`It’s often a cumbersome process to source, staff, and recruit candidates if the demand is in huge volumes. Hence, background screening naturally becomes an integral part of the hiring process, and here's how it helps you:`}
        ul={[
          "Bulk and seasonal staffing is made easy",
          "Automated instant hiring removes obsolete manual discrepancies",
          "Save cost and, more importantly, time",
        ]}
      />
    </section>
    <AllCheck data={DataInfo.StaffingAgencies} />
    <section>
      <SectionTemplate
        img="Staffing/how.svg"
        h2="How is SpringVerify apt for Staffing Agencies?"
        p={`It is vital for any Staffing/Recruiting partner to maintain their brand and reputation by hiring and placing qualified candidates on time. And here's how SpringVerify can help you: `}
        ul={[
          "Real-time instant verification",
          "Seamless ATS/HRMS integration",
          "Multiple screening functionality to support bulk-hiring",
          "Automated workflows which enhance productivity",
          "Flexible prices with lifetime validity of credits",
        ]}
      />
    </section>
    <CustomizedSolutions
      h2={"Customized Solutions built for the \nStaffing Industry"}
      solutions={solutions}
      path="Staffing"
    />
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="Staffing/get-started.svg"
      h3="Ready to give us a try?"
      p="Our innovative screening solutions will help your hire at scale."
    />
    <Footer />
  </div>
);
